import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';


function Header(props) {
  return (
    <div id="shopify-section-header" className="shopify-section">
      <section data-section-id="header" className="header-section" data-section-type="header">
        <header role="banner" className="d-flex align-items-center">
          <div className="container-fluid d-flex flex-wrap-wrap justify-content-between">
            <div className="logo-image">
              <Logo />
            </div>
          </div>
        </header>
      </section>
    </div>
  );
}

export default Header;
