import React from 'react'

function Spinner() {
  return (
    <span class="spinner">
      <span class="bounce1"></span>
      <span class="bounce2"></span>
      <span class="bounce3"></span>
    </span>
  )
}

export default Spinner
