import React, { useState, useRef } from 'react';
import Spinner from './form/Spinner';

function SFWebToLeadForm() {

  const [onSubmit, setOnSubmit] = useState(false);
  const fnRef = useRef();
  const lnRef = useRef();
  const fullNameRef = useRef();

  const handleSubmit = () => {
    setOnSubmit(true);
    // https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8
  }

  const handleFullNameChange = () => {
    fnRef.current.value = fullNameRef.current.value.split(' ').slice(0, -1).join(' ');
    lnRef.current.value = fullNameRef.current.value.split(' ').slice(-1).join(' ');
  }

  return (
    <div>

      <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">

        <input type="hidden" name="oid" value="00D4W000007QbFz" />
        <input type="hidden" name="retURL" value="http://heirjordanent.com/?m=welcome" />

        <label htmlFor="full_name">Your Name</label>
        <input id="full_name" maxLength="100" name="full_name" size="20" type="text" required ref={fullNameRef} onChange={handleFullNameChange} /><br />

        <input type="hidden" id="first_name" maxLength="40" name="first_name" size="20" ref={fnRef} />
        <input type="hidden" id="last_name" maxLength="80" name="last_name" size="20" ref={lnRef} />

        <label htmlFor="email">Your Email</label>
        <input id="email" maxLength="80" name="email" size="20" type="text" required /><br />

        <button className="submitBtn" onClick={handleSubmit}>
          {onSubmit ? <Spinner /> : "Submit"}
        </button>

      </form>

    </div>
  )
}

export default SFWebToLeadForm
