import React from 'react';
import './styles/App.scss';
import Header from './components/header/Header';
import Hero from './components/hero/Hero';
import Banner from './components/banner/Banner';
import ReactModal from 'react-modal';
import { ReactComponent as CloseModal } from './assets/close.svg';
import SFWebToLeadForm from './components/SFWebToLeadForm';

function App() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalWelcome, setmodalWelcome] = React.useState(false);

  React.useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get('m') === "welcome") {
      setmodalWelcome(true);
      setModalOpen(true);
    }

  }, [])

  return (
    <div className="App">
      <Header setModalOpen={setModalOpen} />
      <Hero />
      <Banner setModalOpen={setModalOpen} />
      <ReactModal
        isOpen={modalOpen}
        className="hj-modal"
        appElement={document.getElementById('root')}
        overlayClassName="hj-overlay"
        onRequestClose={() => { setModalOpen(false); setmodalWelcome(false); }}>
        {modalWelcome ?
          <div className="welcome">
            <button className="closeModal" onClick={() => { setModalOpen(false); setmodalWelcome(false); }}><CloseModal /></button>
            <h3>You're In</h3>
            <h1>Thanks For Joining</h1>
          </div>
          :
          <React.Fragment>
            <button className="closeModal" onClick={() => setModalOpen(false)}><CloseModal /></button>
            <h1>JOIN THE FAMILY</h1>
            <SFWebToLeadForm />
          </React.Fragment>
        }

      </ReactModal>
    </div>
  );
}

export default App;
