import React from 'react';

function Hero(props) {

  return (
    <div className="hero-section-wrapper bg-white">
      <section className="hero-section text-center pt-4-5 pb-3 pb-md-4 pb-lg-6 pb-xl-7 d-flex align-items-center justify-content-center bg-cover">
        <video playsInline autoPlay muted loop preload="auto" src="https://player.vimeo.com/external/473127009.hd.mp4?s=3b7b3d6574a2672fe670142455a7cb6186fe677d&profile_id=175"></video>
      </section>
    </div>
  );
}

export default Hero;
