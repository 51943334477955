import React from 'react';

function Banner(props) {

  const createAccountText = ' JOIN THE FAMILY ';

  function handleClick() {
    props.setModalOpen(true);
  }

  return (
    <div className="banner-wrapper bg-secondary">
      <button onClick={handleClick} className="ticker-banner-section signup-form-trigger btn d-block bg-secondary">
        <div className="container-fluid d-flex">
          <div className="ticker">
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
          </div>
          <div className="ticker ticker-copy">
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
            <p className="h4 d-inline-block ticker-item">&nbsp;&nbsp;{createAccountText}&nbsp;&nbsp;</p>
          </div>
        </div>
      </button>
    </div>
  );
}

export default Banner;
